import * as React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroImage from "../components/pagecmp/HeroImage"
import entretien from "../assets/images/tt-entretien.png"
import PageHeader from "../components/pagecmp/PageHeader"
import PageP from "../components/pagecmp/PageP"
import ServicesList from "../components/pagecmp/ServicesList"
import CardIcon from "../components/Card"
import { TbFlame } from "react-icons/tb"
import { RiWindyFill, RiWaterFlashLine } from "react-icons/ri"

import BrandsBanner from "../components/pagecmp/BrandsBanner"

const entretienData = {
  paragraph:
    "Malheureusement l’obsolescence programmée, le calcaire et le temps dégradent vos installations de chauffage et sanitaire. Heureusement, l’entretien prolonge la durée de vie de vos installations. Cela vous évite de nombreuses surprises, met votre logement en sécurité, et réduit votre facture d’eau et de gaz.",
  servicelist: [
    "chaudière au gaz",
    "radiateurs",
    "cannalisations",
    "robineterie",
    "siphon",
    "canalisation eau propre",
    "canalisation eau usée",
    "canalisation eau pluviale",
    "réseau d'égouttage",
  ],
  pagetitle: "d'entretien",
}

const Entretien = props => (
  <Layout>
    <HeroImage title="entretien" img={entretien} />
    <PageHeader title="Des radiateurs bruyants ?" color="white" />
    <PageHeader title="Une chaudière fainéante ?" color="white" />
    <PageHeader title="On sait pourquoi !" color="orange" />{" "}
    <PageP paragraph={entretienData.paragraph} />
    <IconsRow>
      <CardIcon
        avatar={<TbFlame size={80} color={"var(--orange)"} />}
        title="Chaudière et chauffage"
        color={"var(--orange)"}
      />

      <CardIcon
        avatar={<RiWaterFlashLine size={80} color={"var(--orange)"} />}
        title="Détartrage"
        color={"var(--orange)"}
      />

      <CardIcon
        avatar={<RiWindyFill size={80} color={"var(--orange)"} />}
        title="Curage canalisations"
        color={"var(--orange)"}
      />
    </IconsRow>
    <ServicesList
      servicelist={entretienData.servicelist}
      pagetitle={entretienData.pagetitle}
    />
    <BrandsBanner />
  </Layout>
)

export const Head = () => <Seo title="Page entretien" />

export default Entretien

const IconsRow = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 2%;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`
